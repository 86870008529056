@import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Libre+Baskerville&family=Quicksand:wght@500&display=swap');

:root{
  --primarybackground : #5C6B84;
  --secondarybackground:#3A4454;
  --highlight:#E8E9F3;
--highlightblue: #879EC6;
  --okgreen :#7FB285 ;
  --errorred:#C3423F;
  --text: "#000000";
--headline : 'League Spartan', sans-serif;
--headlinesize: 3rem;
--headlinespace:0.2rem;
--bodytext :'Libre Baskerville', serif;
--headerthin : 200;
--headerbold:300
}
#root{
  width: 100%;
  height: 100%;
}
h1 {
  font-family: var(--headline);
  font-size: 3rem;
  margin:0;
letter-spacing: var(--headlinespace);
font-weight: var(--headerbold);

}

h2{
  font-family: var(--headline);
  font-size: 3rem;
  margin:0;
letter-spacing: var(--headlinespace);
}
h3 {
  font-family: var(--headline);
  font-size: 3rem;
  margin:0;
letter-spacing: var(--headlinespace);
}
h4  {
  font-family: var(--headline);
  font-size: 3rem;
  margin:0;
letter-spacing: var(--headlinespace);
}
h5  {
  font-family: var(--headline);
  font-size: 1.5rem;
  margin:0;
letter-spacing: var(--headlinespace);
font-weight: var(--headerthin);
}
h6 {
  font-family: var(--headline);
  font-size: 1rem;
  margin:0;
letter-spacing: var(--headlinespace);
font-weight: var(--headerthin);
cursor: pointer;
}
p{
  font-family: var(--bodytext);
}
.outer{
  min-width: 100vw;
  min-height: 100vh;
 display: flex;
  background-color: var(--primarybackground);
}
.inner{
 
  padding: 1rem;
  
}
.twoColumn{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.textbox{
  background-color: var(--highlight);
  border-radius: 5px;
  padding: 1rem;
  border: solid 1px var(--highlightblue);
  }
.background{
  background-color: var(--primarybackground);
}
.header-outer{
max-width: 25%;
min-height: 100%;
background-color: var(--secondarybackground);
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 0.5rem;}
.header-logo{
height: 15%;
width: 75%;
  background-image: url("./Assests/texelLogo.png");
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 1rem;
}
.headline-container{
  border-bottom: solid 0.5rem var(--highlightblue);
  margin-bottom: 2rem;
  border-radius: 5px;
}
.navBar-item{
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.navBar-mini{
  display: none;
}
.contact-form{
  display: grid;
  grid-template-columns: 1;
  background-color: var(--highlight);
  padding: 1rem;
  gap: 1rem;
  border-radius: 5px;
}
.btn-container{
  width: 100%;
  display: flex;
align-items: center;
justify-content: space-between;
}
.btn-go{
  width: 8rem;
  background-color: var(--okgreen);
  height: 3rem;
  border: 0;
  color: var(--text);
  border-radius: 5px;
}
.btn-cancel{
  width: 8rem;
  background-color: var(--secondarybackground);
  height: 3rem;
  border: 0;
  color: var(--highlight);
  border-radius: 5px;
}


@media (max-width:600px) {
  .outer{
    flex-direction: column;
  }
.header-logo{
  display:none
}
.headline-container{
  text-align: center;
}
.header-outer{
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  min-height: 10%;

}
.navBar-item{
  display:none
}
.navBar-mini{
  display: block;
  flex: 1;
  display: flex;
  align-items: center;
 
  padding: 1rem;
}
h6 {

  font-size: 1rem;
  margin:0;
  padding-left: 0.2rem;
letter-spacing: 0;
font-weight: var(--headerbold);
cursor: pointer;
border-left:solid thin var(--highlightblue);
}
.twoColumn{
  grid-template-columns: 1fr;
}
}